var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"media-modal",attrs:{"size":"xl","hide-footer":"","title":"Media Picker"}},[_c('div',{staticClass:"d-block text-center"},[_c('form',{on:{"submit":function($event){return _vm.doSearch()}}},[_c('base-input',{attrs:{"type":"text","append-icon":"fas fa-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('div',{staticClass:"row mb-5"},_vm._l((_vm.mediaItems),function(item,index){return _c('div',{key:index,staticClass:" mediaItem col-12 col-md-4 col-lg-3",on:{"click":function($event){return _vm.selectMedia(item)}}},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('div',{staticClass:"thumbnail bg-light rounded ",staticStyle:{"width":"100px","height":"100px"}},[_c('b-img',{staticStyle:{"max-height":"100px","width":"100px"},attrs:{"thumbnail":"","fluid":"","src":_vm.getSourceFromFile(item.file.filename, 'thumbnails'),"alt":"Image 1"}})],1),_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticStyle:{"font-size":"1rem"}},_vm._l((item.tags),function(tag,index){return _c('b-badge',{key:index,staticClass:"mx-1",attrs:{"variant":"secondary"}},[_vm._v(_vm._s(tag))])}),1)])])}),0),_c('div',{staticClass:"d-flex flex-row justify-content-center"},[_c('base-pagination',{staticClass:"flex-0",attrs:{"per-page":_vm.perPage,"total":_vm.total},on:{"change":_vm.reloadRows},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]),_c('validation-provider',_vm._b({attrs:{"rules":_vm.rules,"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('b-form-group',{class:{'mb-0': _vm.nomargin}},[_vm._t("label",function(){return [(_vm.label)?_c('label',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('span',[_vm._v(" *")]):_vm._e()]):_vm._e()]}),_c('div',[_vm._t("default",function(){return [_c('div',{staticClass:"media_field border-2 border rounded bg-light",staticStyle:{"width":"100px","height":"100px"},on:{"click":_vm.showModal}},[(_vm.value)?_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.getImageById(_vm.value, 'thumbnails')}}):_vm._e()]),_c('i',{staticClass:"fa fa-trash",on:{"click":function($event){return _vm.clearImage()}}}),_c('input',_vm._g(_vm._b({staticClass:"form-control",class:[
              { 'is-valid': valid && validated && _vm.successMessage },
              { 'is-invalid': invalid && validated } ],attrs:{"hidden":true,"type":"hidden","valid":valid,"required":_vm.required},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners))]},null,_vm.slotData),_vm._t("infoBlock")],2),_vm._t("success",function(){return [(valid && validated && _vm.successMessage)?_c('div',{staticClass:"valid-feedback"},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e()]}),_vm._t("error",function(){return [(errors[0])?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" Required ")]):_vm._e()]})],2)]}}],null,true)},'validation-provider',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }