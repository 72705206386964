import axios from "axios"

export const getAll = (format, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `media/?format=${format}&search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const getAllByGuestId = (guestId, format, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `media/guest/${guestId}?format=${format}&search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const getShortListByGuestId = (guestId, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `media/guest/${guestId}/list`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const getImageByIdBYSize = (id, size) => {
  return axios.get(process.env.VUE_APP_API_URL + `media/image/` + id + '/' + size)
}


export const getById = (id, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `media/` + id, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const upload = (image, imagename, jsondata, token) => {
  const formData = new FormData()
  formData.append('file', image, imagename)
  formData.append('document', JSON.stringify(jsondata))
  return axios.post(process.env.VUE_APP_API_URL + `media`, formData, {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  });
}

export const uploadToGuest = (image, imagename, jsondata, token, guestId) => {
  const formData = new FormData()
  formData.append('file', image, imagename)
  formData.append('document', JSON.stringify(jsondata))
  return axios.post(process.env.VUE_APP_API_URL + `media/guest/${guestId}`, formData, {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  });
}
export const update = (id, mediaForm, token) => {
  return axios.put(process.env.VUE_APP_API_URL + `media/` + id, {
    //username: user.username,
    //password: user.password,
   ...mediaForm

  }, {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}



export const deleteR = (id, token) => {
  return axios.delete(process.env.VUE_APP_API_URL + `media/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}