<template>
  <div>
    <b-modal size="xl" ref="media-modal" hide-footer title="Media Picker">
      <div class="d-block text-center">
        <form @submit="doSearch()">
          <base-input
            type="text"
            v-model="search"
            append-icon="fas fa-search" 
          />
        </form>
      </div>
   
        <div class="row mb-5">
          <div @click="selectMedia(item)"  v-for="(item,index) in mediaItems" :key="index" class=" mediaItem col-12 col-md-4 col-lg-3">
            <div class="d-flex flex-column align-items-center">
              <div style="width:100px;height:100px;" class="thumbnail bg-light rounded ">
                <b-img style="max-height:100px;width:100px;" thumbnail fluid :src="getSourceFromFile(item.file.filename, 'thumbnails')" alt="Image 1"></b-img>
              </div>
            <div>{{item.name}}</div>
            <div style="font-size:1rem"><b-badge class="mx-1" v-for="(tag, index) in item.tags" :key="index"  variant="secondary">{{tag}}</b-badge></div>
            </div>
          </div>
        </div>
        
        <div class="d-flex flex-row justify-content-center">
         
            <base-pagination class="flex-0"  @change="reloadRows" v-model="currentPage" :per-page="perPage" :total="total"></base-pagination>
        </div>
       
    </b-modal>

    <validation-provider
      :rules="rules"
      :name="name"
      v-bind="$attrs"
      v-slot="{ errors, valid, invalid, validated }"
    >
      <b-form-group :class="{'mb-0': nomargin}">
        
        <slot name="label">
          <label v-if="label" class="form-control-label">
            {{ label }}<span v-if="required">&nbsp;*</span>
          </label>
        </slot>
        <div>
          <slot v-bind="slotData">
            <div @click="showModal"
              class="media_field border-2 border rounded bg-light"
              style="width: 100px; height: 100px;"
            >
              <img v-if="value" style="width: 100%; height: 100%" :src="getImageById(value, 'thumbnails')" />
            </div>
              <i class="fa fa-trash" @click="clearImage()"></i>
            <input
              :hidden="true"
              :value="value"
              type="hidden"
              v-on="listeners"
              v-bind="$attrs"
              :valid="valid"
              :required="required"
              class="form-control"
              :class="[
                { 'is-valid': valid && validated && successMessage },
                { 'is-invalid': invalid && validated },
                
              ]"
            />
          </slot>
          <slot name="infoBlock"></slot>
        </div>
        <slot name="success">
          <div
            class="valid-feedback"
            v-if="valid && validated && successMessage"
          >
            {{ successMessage }}
          </div>
        </slot>
        <slot name="error">
          <div v-if="errors[0]" class="invalid-feedback" style="display: block">
            Required
          </div>
        </slot>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<style scoped>
  .mediaItem {
    cursor: pointer;
  }
  .mediaItem:hover .thumbnail {
    border: 1px solid;
  }
  .media_field {
    overflow:hidden;
     cursor:pointer
  }
  .media_field:hover {
    border-color:black !important;
    
  }
  
</style>

<script>
import { getImageById, getSourceFromFile } from "@/util/imageSource";
import * as mediaRepo from '@/api-repo/media'

export default {
  inheritAttrs: false,
  name: "media-input",
  inject: ["userStore"],
  props: {
    format: {
      type: String,
      default: 'image'
    },
    guestId:{
      type: String,
      default: null
    },
    nomargin: {
      type: Boolean
    },
    required: {
      type: Boolean
    },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
    error: {
      type: String,
      description: "Input error (below input)",
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: "",
    },
   
    value: {
      type: [String, Number],
      description: "Input value",
    },
   
   
    rules: {
      type: [String, Array, Object],
      description: "Vee validate validation rules",
      default: "",
    },
    name: {
      type: String,
      description: "Input name (used for validation)",
      default: "",
    },
  },
  data() {
    return {
      //media: null,
      mediaItems: [],
      searchTerm: null,
      isBusy: false,
      currentPage: 1,
      perPage: 8,
      total: 1,
     // rows: [],
      search: null,
      fields: [
        {key: 'image', title: 'Image'},
       
        {key: 'tags', title: 'Tags'},
      
      ],
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
      };
    },
    slotData() {
      return {
        error: this.error,
        ...this.listeners,
      };
    },
  },
  created() {
    this.getMediaPage();
  },
  methods: {
     getImageById,
     getSourceFromFile,
    doSearch() {
      this.getMediaPage()
    },
    selectMedia(item) {
      //this.media = item
      let value = item._id;
      //this.value = item.id;
      this.$emit("input", value);
      this.hideModal();
    },
    showModal() {
      this.$refs['media-modal'].show()
    },
    hideModal() {
      this.$refs['media-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['media-modal'].toggle('#toggle-btn')
    },
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit("input", value);
    },
     clearImage() {

      this.$emit("remove", null);
    },
   async getMediaPage() {
      this.isBusy = true;
      let result = 
      this.guestId ? 
      await mediaRepo.getAllByGuestId(this.guestId, this.format, this.search || '', this.currentPage, this.perPage, this.userStore.state.token):
      await mediaRepo.getAll(this.format, this.search || '', this.currentPage, this.perPage, this.userStore.state.token);


      this.isBusy = false;
      this.currentPage = result.data.data[0].page + 1;
      this.total = result.data.data[0].total.length && result.data.data[0].total[0].count;
      this.mediaItems =  result.data.data[0].rows;
    },
    reloadRows: function(e) {
      
      this.currentPage = e
      this.getMediaPage();
    },
  },
};
</script>
<style>
</style>
