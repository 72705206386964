const noimage = require('../assets/img/noimage.png');

export const isImage = (name) => {
  return name.endsWith('.png') || name.endsWith('.jpg') || name.endsWith('.jpeg')
}

export const getSourceFromFile = (name, size) => {

  if (!isImage(name)) {
    return process.env.VUE_APP_API_URL.replaceAll('\/api','\/uploads') + "/assets/docicon.png"
  }
  return process.env.VUE_APP_API_URL.replaceAll('\/api','\/uploads') + size + "/" + name
}

export const getImageById = (id, size) => {
  if (!id) {
    return noimage
  }
  return process.env.VUE_APP_API_URL + "media/image/" + id + "/" + size
}